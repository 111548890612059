import { useEffect, useState } from 'react'
import './ModifyIntakeForm6.css'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
import { formNotificationStyle2 } from '../../utilities/services/formNotification';
import { updateForm } from '../../utilities/api/allForms';
import IntakeFormNotes from '../IntakeFormNotes/IntakeFormNotes';

export default function ModifyIntakeForm6({form, services, updateIntakeForm}) {
    const fallback = 'No information provided'
    const date = new Date();
    const potentialInventorsQuantity = 5;
    const requiredPotentialInventors = 1;
    const relevantDocumentsQuantity = 9;
    const requiredRelevantDocuments = 1;
    const defaultFormData = {
        clientID: form.clientID,
        projectNumber: form.projectNumber,
        status: form.status,
        submissionDate: date.toISOString().split('T')[0],
        clientNumber: form.clientNumber,
        service: form.service,
        companyName: form.companyName,
        companyAddress: form.companyAddress,
        companyRepresentative: capitalizeFirstLetter(convertToString(form.companyRepresentative)),
        titleOfInvention: (form.titleOfInvention&& form.titleOfInvention) || '',
        inventor1: form.inventor1 || '',
        inventor2: form.inventor2 || '',
        inventor3: form.inventor3 || '',
        inventor4: form.inventor4 || '',
        inventor5: form.inventor5 || '',
        inventionDisclosure: form.inventionDisclosure || '',
        inventionDisclosureDescription: form.inventionDisclosureDescription || '',
        countryOfInterest: form.countryOfInterest || '',
        document1: form.document1 || '',
        document1Description: form.document1Description || '',
        document2: form.document2 || '',
        document2Description: form.document2Description || '',
        document3: form.document3 || '',
        document3Description: form.document3Description || '',
        document4: form.document4 || '',
        document4Description: form.document4Description || '',
        document5: form.document5 || '',
        document5Description: form.document5Description || '',
        document6: form.document6 || '',
        document6Description: form.document6Description || '',
        document7: form.document7 || '',
        document7Description: form.document7Description || '',
        document8: form.document8 || '',
        document8Description: form.document8Description || '',
        document9: form.document9 || '',
        image9Description: form.image9Description || '',
        objectivesOfInvention: form.objectivesOfInvention || '',
    }
    const listOfCountries = ['US', 'Canada', 'EU', 'Australia', 'India', 'China']
    const [generalInformationComplete, setGeneralInformationComplete] = useState(true);
    const [renderInventionDisclosureDescription, setRenderInventionDisclosureDescription] = useState(true);
    const [technicalSection1Complete, setTechnicalSection1Complete] = useState(true);
    const [technicalSection2Complete, setTechnicalSection2Complete] = useState(true);// set to true since section is optional
    const tabCompletion = {
        0: generalInformationComplete,
        1: technicalSection1Complete,
        2: technicalSection2Complete,
    };
    const [disableAllFields] = useState(['complete'].includes(defaultFormData.status) ? true : false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData] = useState(new FormData());
    const tabs = ['generalInformation', 'technicalSection1', 'technicalSection2'];
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    function convertToString(array) {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const handleNextClick = async (evt) => {
        // const activeTab = document.getElementById(tabs[activeTabIndex]);
        const tabCompletionStatus = tabCompletion;
        if (!tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else if (activeTabIndex < tabs.length-1) {
            // activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(activeTabIndex + 1);
        }
    }

    const handleBackClick = (evt) => {
        if (activeTabIndex > 0) {
            setActiveTabIndex(activeTabIndex - 1);
        }
    }

    const handleTabClick = (index) => {
        const tabCompletionStatus = tabCompletion;
        // const activeTab = document.getElementById(tabs[activeTabIndex]);
        if (index > activeTabIndex && !tabCompletionStatus[activeTabIndex]) {
            formNotificationStyle2('tabSwitchNotification', 'Please complete all required fields before proceeding!', '#CA150C', 0.8 )
        } else {
            // activeTab.scrollIntoView({behavior: 'smooth', block: 'start'});
            setActiveTabIndex(index);
        }
    }

    const handleFormChange = (evt) => {
        if (evt.target.name === 'inventionDisclosure') {
            formData.set(evt.target.name, evt.target.value);
            formData.set('inventionDisclosureDescription', '');
            if (evt.target.value === 'N' || evt.target.value === '') {
                setRenderInventionDisclosureDescription(false);
            } else {
                setRenderInventionDisclosureDescription(true);
            }
        } else {
            formData.set(evt.target.name, evt.target.value);
        }
        // console.log(Object.fromEntries(formData.entries()));
        checkFormCompletion(formData);
        // console.log(formData)
    }

    const checkFormCompletion = (formData) => {
        const requiredFieldsByTab = {
            0: ['companyName', 'companyAddress', 'companyRepresentative', 'titleOfInvention', 'inventor1', 'inventionDisclosure', 'countryOfInterest', renderInventionDisclosureDescription && 'inventionDisclosureDescription'],
            1: ['document1', 'document1Description'],
            2: ['objectivesOfInvention'],
        };
        const checkFormCompletion = (fields) => {
            let isComplete = true;
            for (var pair of formData.entries()) {
                if (fields.includes(pair[0]) && pair[1] === '') {
                    isComplete = false;
                }
            }
            return isComplete;
        };
        if (activeTabIndex in requiredFieldsByTab) {
            const isComplete = checkFormCompletion(requiredFieldsByTab[activeTabIndex]);
            if (activeTabIndex === 0) setGeneralInformationComplete(isComplete);
            if (activeTabIndex === 1) setTechnicalSection1Complete(isComplete);
            if (activeTabIndex === 2) setTechnicalSection2Complete(isComplete);
        }
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault();
        // console.log(Object.fromEntries(formData.entries()));
        setIsSubmitting(true);
        formData.set('status', 'changesSubmitted')
        const data = Object.fromEntries(formData.entries());
        try {
            const response = await updateForm(form._id, data);
            // console.log(response.form)
            if (response.message === 'Form updated') {
                formNotificationStyle2('tabSwitchNotification', 'Changes successfully submitted!', '#0A7637', 0.8)
                setTimeout(() => {
                    // reload page
                    window.location.reload();
                }, 1500);
            } else {
                formNotificationStyle2('tabSwitchNotification', 'Changes could not be submitted!', '#CA150C', 0.8)
                console.log(response.message);                
            }
            setIsSubmitting(false);
        } catch (error) {
            setIsSubmitting(false);
            formNotificationStyle2('tabSwitchNotification', 'Changes could not be submitted!', '#CA150C', 0.8)
            console.log(error);
        }
    }

    useEffect(() => {
        setIsFormComplete(generalInformationComplete && technicalSection1Complete && technicalSection2Complete);
    },[generalInformationComplete, technicalSection1Complete, technicalSection2Complete])


    useEffect(() => {
        for (var pair of Object.entries(defaultFormData)) {
                formData.append(pair[0], pair[1]);
        }
        // console.log(Object.fromEntries(formData.entries()));
        if (defaultFormData.inventionDisclosure === 'N' || defaultFormData.inventionDisclosure === '') {
            setRenderInventionDisclosureDescription(false);
        } else {
            setRenderInventionDisclosureDescription(true);
        }
        // formData.append('fileUploads', [])
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        checkFormCompletion(formData);
        //eslint-disable-next-line
    }, [formData, defaultFormData])
  return (
        <div className='ModifyIntakeForm6 modifyIntakeFormGlobal'>
            <div className='upperSection'>
                <div className='info'>
                    <div className='firstSection'>
                        <div className='serviceTitle'>
                        {`${services.find(service => service.code === form.service).name}`}
                        </div>
                        <div className={`formStatus ${form.status}`}>
                            { ['inProgress', 'submitted', 'changesApproved'].includes(form.status) &&
                            <svg width="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.5 0.96875C4.78854 0.96875 0.96875 4.78854 0.96875 9.5C0.96875 14.2115 4.78854 18.0312 9.5 18.0312C14.2115 18.0312 18.0312 14.2115 18.0312 9.5C18.0312 4.78854 14.2115 0.96875 9.5 0.96875ZM13.4375 10.8125H9.5C9.32595 10.8125 9.15903 10.7434 9.03596 10.6203C8.91289 10.4972 8.84375 10.3303 8.84375 10.1562V4.25C8.84375 4.07595 8.91289 3.90903 9.03596 3.78596C9.15903 3.66289 9.32595 3.59375 9.5 3.59375C9.67405 3.59375 9.84097 3.66289 9.96404 3.78596C10.0871 3.90903 10.1562 4.07595 10.1562 4.25V9.5H13.4375C13.6115 9.5 13.7785 9.56914 13.9015 9.69221C14.0246 9.81528 14.0938 9.9822 14.0938 10.1562C14.0938 10.3303 14.0246 10.4972 13.9015 10.6203C13.7785 10.7434 13.6115 10.8125 13.4375 10.8125Z" fill="#DFB200"/>
                            </svg>                
                            }
                            { ['complete'].includes(form.status) &&
                            <svg width="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.5 1.96875C5.79592 1.96875 1.96875 5.79592 1.96875 10.5C1.96875 15.2041 5.79592 19.0312 10.5 19.0312C15.2041 19.0312 19.0312 15.2041 19.0312 10.5C19.0312 5.79592 15.2041 1.96875 10.5 1.96875ZM14.9399 7.6408L9.42744 14.2033C9.36697 14.2753 9.29173 14.3335 9.20681 14.3739C9.12189 14.4143 9.02928 14.436 8.93525 14.4375H8.92418C8.8322 14.4375 8.74125 14.4181 8.65723 14.3806C8.57322 14.3432 8.49802 14.2885 8.4365 14.2201L6.074 11.5951C6.014 11.5315 5.96733 11.4565 5.93673 11.3746C5.90612 11.2926 5.89221 11.2054 5.89579 11.118C5.89938 11.0306 5.9204 10.9448 5.95762 10.8657C5.99483 10.7865 6.04749 10.7156 6.11251 10.6571C6.17752 10.5986 6.25357 10.5537 6.33619 10.525C6.41881 10.4963 6.50634 10.4844 6.59362 10.49C6.6809 10.4956 6.76618 10.5187 6.84444 10.5577C6.9227 10.5967 6.99237 10.651 7.04936 10.7174L8.90695 12.7813L13.9351 6.7967C14.0478 6.6663 14.2074 6.58553 14.3793 6.57183C14.5511 6.55814 14.7215 6.61262 14.8535 6.72351C14.9855 6.8344 15.0686 6.99279 15.0848 7.16444C15.1009 7.33608 15.0489 7.5072 14.9399 7.6408Z" fill="#158444"/>
                            </svg>                    
                            }
                            <span className='statusText'>
                            {
                            form.status === 'new' ? 'New' : form.status === 'inProgress' ? 'In Progress' : form.status === 'submitted' ? 'Submitted' : form.status === 'complete' ? 'Complete' : form.status === 'changesSubmitted' ? 'Changes Made' : form.status === 'changesApproved' ? 'Changes Approved' : ''
                            }
                            </span>
                        </div>
                    </div>
                    <div className='notesContainer'>
                        <button className='notesButton dropdown' id={`formNotes`} data-title={`Add & View Form Notes`} data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#5F6B7A" className="bi bi-sticky-fill" viewBox="0 0 16 16">
                                <path d="M2.5 1A1.5 1.5 0 0 0 1 2.5v11A1.5 1.5 0 0 0 2.5 15h6.086a1.5 1.5 0 0 0 1.06-.44l4.915-4.914A1.5 1.5 0 0 0 15 8.586V2.5A1.5 1.5 0 0 0 13.5 1zm6 8.5a1 1 0 0 1 1-1h4.396a.25.25 0 0 1 .177.427l-5.146 5.146a.25.25 0 0 1-.427-.177z"/>
                            </svg>
                            <span>&nbsp;&nbsp;Notes</span>
                        </button>
                        <IntakeFormNotes formID={form._id} notes={form.notes} updateIntakeForm={updateIntakeForm} />
                    </div>
                </div>
                <div className="nav nav-pills flex-row navigation" id="v-pills-tab" role='tablist' aria-orientation="horizontal">
                {tabs.map((tab, index) => (
                    <button key={tab} onClick={() => handleTabClick(index)} className={`${tab} nav-link ${activeTabIndex === index ? 'active' : ''}`} id={`${tab}Tab`} type="button">
                        {tab === 'generalInformation' ? 'General Information' : tab === 'technicalSection1' ? 'Technical Section 1' : 'Technical Section 2'}
                    </button>
                ))}
                </div>
                <div className='content tab-content' id="v-pills-tabContent">
                    <form className='' id='intakeForm6' formEncType='multipart/form-data' onSubmit={handleSubmit}>
                    {tabs.map((tab, index) => (
                        <div key={tab} className={`tab-pane fade ${activeTabIndex === index ? 'show active' : ''}`} id={tab} role="tabpanel" aria-labelledby={`${tab}Tab`} >
                            {tab === 'generalInformation' &&
                            <div className='generalInformation'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>General Information</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyName'>Legal name of client (company/person) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyName' name='companyName' defaultValue={defaultFormData.companyName} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyAddress'>Legal address of client <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyAddress' name='companyAddress' defaultValue={defaultFormData.companyAddress} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                        <div className='formGroup'>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='companyRepresentative'>Full name of representative(s) <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='companyRepresentative' name='companyRepresentative' defaultValue={defaultFormData.companyRepresentative} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                            <div className='formGroupItem'>
                                                <label className='form-label' htmlFor='titleOfInvention'>Title of invention <span className='requiredField'><strong>*</strong></span></label>
                                                <input className='form-control' type='text' id='titleOfInvention' name='titleOfInvention' defaultValue={defaultFormData.titleOfInvention} onChange={handleFormChange} required disabled={disableAllFields} />
                                            </div>
                                        </div>
                                        <div className='formGroupItem'>
                                            <label className='form-label' htmlFor='countryOfInterest'>Country of Interest <span className='requiredField'><strong>*</strong></span></label>
                                            <select className='form-select' id='countryOfInterest' name='countryOfInterest' onChange={handleFormChange} defaultValue={defaultFormData.countryOfInterest} required disabled={disableAllFields}>
                                                <option value=''>Select Country</option>
                                                { listOfCountries.map((country, index) => (
                                                    <option key={index} value={country}>{country}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>List of Potential Inventors</span>
                                    </div>
                                    <div className='sectionContent'>
                                    {[...Array(potentialInventorsQuantity)].map((element, index) => (
                                        <div key={index} className='mb-4'>
                                            <label className='form-label' htmlFor={`inventor${index+1}`}>Name & Address {index+1} {index<requiredPotentialInventors && <span className='requiredField'><strong>*</strong></span>}</label>
                                            <input className='form-control' type='text' id={`inventor${index+1}`} name={`inventor${index+1}`} defaultValue={defaultFormData[`inventor${index+1}`]} onChange={handleFormChange} required={index<requiredPotentialInventors} disabled={disableAllFields} autoComplete='false' />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Invention Disclosure</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <div>Has this invention been publicly disclosed, sold, or offered for sale, yet? <span className='requiredField'><strong>*</strong></span></div>
                                        <div className='radioButtonContainer'>
                                            <div className={`radioButton ${formData.get('inventionDisclosure') === 'Y' ? 'active' : ''}`}>
                                                <label htmlFor="inventionDisclosureYes">
                                                    <input type="radio" name="inventionDisclosure" id="inventionDisclosureYes" value="Y" onChange={handleFormChange} defaultChecked={defaultFormData.inventionDisclosure === 'Y'} required disabled={disableAllFields} />
                                                    Yes
                                                </label>
                                            </div>
                                            <div className={`radioButton ${formData.get('inventionDisclosure') === 'N' ? 'active' : ''}`}>
                                                <label htmlFor="inventionDisclosureNo">
                                                    <input type="radio" name="inventionDisclosure" id="inventionDisclosureNo" value="N" onChange={handleFormChange} defaultChecked={defaultFormData.inventionDisclosure === 'N'} required disabled={disableAllFields} />
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    {(renderInventionDisclosureDescription) &&
                                    <div className='sectionContent'>
                                        <label className='form-label' htmlFor='inventionDisclosureDescription'>Please describe the circumstances under which it was disclosed and the date(s) of first disclosure. <span className='requiredField'><strong>*</strong></span></label>
                                        <textarea className='form-control' id='inventionDisclosureDescription' rows={2} name='inventionDisclosureDescription' defaultValue={formData.get('inventionDisclosureDescription')} onChange={handleFormChange} disabled={disableAllFields} />
                                    </div>
                                    }
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection1' &&
                            <div className='technicalSection1'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Technical Section</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <ol className='smallFont'>
                                            <li>
                                                This Intake Form intends to collect the information needed for intellectual property research. Riahi Patents will primarily use the information provided below as the input in the research, so <span  className='blueColorFont'>please include all the relevant information of your invention and fill out this form carefully</span>.
                                            </li>
                                            <li>
                                                If you have any doubts regarding this process, <span className='blueColorFont'>please reach out to Riahi Patents before delivering this intake form</span>. It may not be possible to add new information once the research has begun.
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                                <div className='smallFont italicBlueColorFont blueBack'>Please fill out and return this form to Riahi Patents no later than three business days after the date stated on the identification table</div>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Relevant Documents</span>
                                    </div>
                                    <div className='sectionContent strongFont  pb-0'>Please include any drafts, figures, drawings that your company considers being related to the invention / design.</div>
                                    <div className='demoContainer'>
                                        <div className='smallFont sectionContent italicBlueColorFont pb-0'>
                                            <p className='strongFont mb-0'><i>Minimum: 1</i></p>
                                            <p className='strongFont mb-0'><i>Maximum: 9</i></p>
                                        </div>
                                    </div>
                                    <div className='sectionContent strongFont errorText'><i>Each file uploaded must not exceed 20MB in size.</i></div>
                                    <div className='sectionContent'>
                                    { [...Array(relevantDocumentsQuantity)].map((element, index) => (
                                        <div key={index} className='mb-4'>
                                            <div className='strongFont'>Document {index+1} {index<requiredRelevantDocuments && <span className='requiredField'><strong>*</strong></span>}</div>
                                            { defaultFormData[`document${index+1}`] ?
                                            <div className='documentContainer mb-2'>
                                                <div className='mb-1'>Uploaded Document Link:&nbsp;
                                                    <a href={defaultFormData[`document${index+1}`]} target='_blank' rel='noreferrer'>
                                                        {defaultFormData[`document${index+1}`]}
                                                    </a>
                                                </div>
                                            </div>
                                            :
                                            <div className='mb-2'>
                                                <div className='mb-1'>Uploaded Document Link:&nbsp;
                                                    <i>{fallback}</i>
                                                </div>
                                            </div>
                                            }
                                            <textarea className='form-control' id={`document${index+1}Description`} name={`document${index+1}Description`} defaultValue={defaultFormData[`document${index+1}Description`]} onChange={handleFormChange} required={index<requiredRelevantDocuments} disabled={disableAllFields || !defaultFormData[`document${index+1}`]} />
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            }
                            {tab === 'technicalSection2' &&
                            <div className='technicalSection2'>
                                <div className='mainContainer'>
                                    <div className='strongFont sectionTitle'>
                                        <span className='svgContainer'>
                                            <svg width="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 2.625C6.83063 2.625 2.625 6.83063 2.625 12C2.625 17.1694 6.83063 21.375 12 21.375C17.1694 21.375 21.375 17.1694 21.375 12C21.375 6.83063 17.1694 2.625 12 2.625ZM12 6.46875C12.241 6.46875 12.4767 6.54023 12.6771 6.67415C12.8775 6.80806 13.0337 6.99841 13.126 7.2211C13.2182 7.4438 13.2424 7.68885 13.1953 7.92527C13.1483 8.16168 13.0322 8.37884 12.8618 8.54929C12.6913 8.71973 12.4742 8.83581 12.2378 8.88283C12.0014 8.92986 11.7563 8.90572 11.5336 8.81348C11.3109 8.72123 11.1206 8.56502 10.9866 8.3646C10.8527 8.16418 10.7812 7.92855 10.7812 7.6875C10.7812 7.36427 10.9097 7.05427 11.1382 6.82571C11.3668 6.59715 11.6768 6.46875 12 6.46875ZM14.25 17.0625H10.125C9.92609 17.0625 9.73532 16.9835 9.59467 16.8428C9.45402 16.7022 9.375 16.5114 9.375 16.3125C9.375 16.1136 9.45402 15.9228 9.59467 15.7822C9.73532 15.6415 9.92609 15.5625 10.125 15.5625H11.4375V11.4375H10.6875C10.4886 11.4375 10.2978 11.3585 10.1572 11.2178C10.0165 11.0772 9.9375 10.8864 9.9375 10.6875C9.9375 10.4886 10.0165 10.2978 10.1572 10.1572C10.2978 10.0165 10.4886 9.9375 10.6875 9.9375H12.1875C12.3864 9.9375 12.5772 10.0165 12.7178 10.1572C12.8585 10.2978 12.9375 10.4886 12.9375 10.6875V15.5625H14.25C14.4489 15.5625 14.6397 15.6415 14.7803 15.7822C14.921 15.9228 15 16.1136 15 16.3125C15 16.5114 14.921 16.7022 14.7803 16.8428C14.6397 16.9835 14.4489 17.0625 14.25 17.0625Z" fill="#1A5E9B"/>
                                            </svg>
                                        </span>
                                        <span>Technical Section</span>
                                    </div>
                                    <div className='sectionContent'>
                                        <label className='form-label' htmlFor='objectivesOfInvention'>Objectives of the Invention / Additional Details of Design <span className='requiredField'><strong>*</strong></span></label>
                                        <textarea className='form-control' rows={3} id='objectivesOfInvention' name='objectivesOfInvention' defaultValue={defaultFormData.objectivesOfInvention} onChange={handleFormChange} disabled={disableAllFields} />
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    ))}
                    </form>
                </div>
            </div>
            <div className='lowerSection'>
                <div className='tabSwitchNotificationContainer'>
                    <div id='tabSwitchNotification'className='tabSwitchNotification text-center'></div>
                </div>
                <div className='navigationButtons'>
                    <div className='backNext'>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='backButton' onClick={handleBackClick} disabled={activeTabIndex>0 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.0249 17.725L1.7999 9.5L10.0249 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className='navigationButtonContainer'>
                            <button className='navButton' type='button' id='nextButton' onClick={handleNextClick} disabled={activeTabIndex !== tabs.length-1 ? false:true}>
                                <svg width="10" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.1499 17.725L9.3749 9.5L1.1499 1.275" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    { ['submitted', 'changesApproved'].includes(defaultFormData.status) &&
                    <>
                    <div type='button' id='cancelButton' className='cancelButton'>
                        <button className='navButton' data-bs-dismiss="modal">Cancel</button>
                    </div>
                    { activeTabIndex === tabs.length-1 &&
                    <div className='navigationButtonContainer'>
                        <button className='submitButton' type='submit' form='intakeForm6' id='submitButton' disabled={!isFormComplete || isSubmitting}>Submit Changes</button>
                    </div>
                    }
                    </>
                    }
                </div>
            </div>
        </div>
    )
}
