import './FormListItem.css'
import { deleteForm } from '../../utilities/api/allForms'
import { generateIntakeFormDocA } from '../../utilities/services/generateIntakeFormDocA'
import { generateIntakeFormDocB } from '../../utilities/services/generateIntakeFormDocB'
import { generateIntakeFormDocC } from '../../utilities/services/generateIntakeFormDocC'
import { generateIntakeFormDocD } from '../../utilities/services/generateIntakeFormDocD'
import { generateIntakeFormDocE } from '../../utilities/services/generateIntakeFormDocE'
import { Link } from 'react-router-dom'
import { generateIntakeFormDocF } from '../../utilities/services/generateIntakeFormDocF'

export default function FormListItem({form, client, updateIntakeForms, services}) {
    const firstUpdateChangeDate = '2024-06-05'
    // const firstUpdateChangeDate = '2023-06-05'

    const deleteFormItem = async (evt) => {
        try {
            await deleteForm(client._id, form._id, form.service)
            updateIntakeForms(client._id, 'hideSpinner')
        } catch (error) {
            console.log(error)
        }
    }

    const generateWordDoc = async (evt) => {
        // console.log(form)
        try {
            if (form.service === 'IPGAPS' || form.service === 'CPAF' || form.service === 'USPAF' || form.service === 'PCTAF' || form.service === 'USNPAF') {
                await generateIntakeFormDocA(form, services)
            } else if (form.service === 'FTOA') {
                await generateIntakeFormDocB(form, services)
            } else if (form.service === 'CTAF'|| form.service === 'USTAF' || form.service === 'CTS' || form.service === 'USTS' || form.service === 'TS' || form.service === 'TAF') {
                await generateIntakeFormDocC(form, services)
            } else if ( form.service === 'IPSA') {
                await generateIntakeFormDocD(form, services)
            } else if ( form.service === 'LA') {
                await generateIntakeFormDocE(form, services)
            } else if ( form.service === 'IDDP') {
                await generateIntakeFormDocF(form, services)
            }else {
                // console.log(form.service)
                window.alert(`Document Generation for ${form.service} is not yet supported`)
            }
        } catch (error) {
            console.log(error)
        }
    }

  return (
    <tr className='FormListItem'>
        <td className='projectNumber'>{form.projectNumber}</td>
        <td className='service'>{form.service}</td>
        <td className='creationDate'><span>{form.createdAt.split('T')[0]}</span></td>
        <td className='formStatus'>
            <span className={`formStatusContainer ${form.status}`}>
                <span className={`statusColor ${form.status}`}></span>
                <span className='statusText'>
                    <strong>
                        {form.status === 'new' ? 'New' : form.status === 'inProgress' ? 'In Progress' : form.status === 'submitted' ? 'Submitted' : form.status === 'complete' ? 'Complete' : form.status === 'changesSubmitted' ? 'Changes Made' : form.status === 'changesApproved' ? 'Changes Approved' : form.status === 'changesRejected' ? 'Changes Rejected' : ''}
                    </strong>
                </span>
            </span>
            
        </td>
        <td className='actions'>
        { form.status==='new' &&
            <div className='actionContainer'>
                <button className='deleteForm' title='Delete form' onClick={deleteFormItem}>
                    <svg width="30" viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="33" height="33" rx="7" stroke="#CA150C" strokeOpacity="0.24" strokeWidth="2"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M23.7366 15.3519C23.7366 15.0989 23.5313 14.8936 23.2783 14.8936H11.182C10.9281 14.8936 10.7237 15.0989 10.7237 15.3519C10.7237 16.2658 10.7072 17.1174 10.6916 17.9066C10.6238 21.3432 10.5788 23.6138 11.9676 25.0301C12.9539 26.0356 14.5737 26.4848 17.2201 26.4848C19.8692 26.4848 21.4917 26.0347 22.4808 25.0273C23.876 23.6046 23.832 21.3231 23.7669 17.8672C23.7522 17.089 23.7366 16.2502 23.7366 15.3519Z" fill="#CA150C"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.6313 11.847H20.8354C20.7978 11.2475 20.6319 10.4023 20.0351 9.71663C19.4044 8.99155 18.4603 8.62305 17.2283 8.62305C15.9972 8.62305 15.0531 8.99155 14.4224 9.71663C13.8256 10.4023 13.6588 11.2475 13.6212 11.847H9.82715C9.44765 11.847 9.13965 12.155 9.13965 12.5345C9.13965 12.914 9.44765 13.222 9.82715 13.222H24.6313C25.0108 13.222 25.3188 12.914 25.3188 12.5345C25.3188 12.155 25.0108 11.847 24.6313 11.847Z" fill="#CA150C"/>
                    </svg>
                </button>
            </div>
            
        }
        { form.createdAt.split('T')[0] >= firstUpdateChangeDate ?
        <>
        { ['submitted', 'changesSubmitted', 'changesApproved'].includes(form.status) &&
            <div className='actionContainer'>
                <Link to={`/reviewIntakeForms/${form._id}`}><button className='reviewForm' type='button'>Review</button></Link>
                <button className='generateDoc' title='Generate Document' onClick={generateWordDoc}>
                    <svg width="30" viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="33" height="33" rx="7" stroke="#1A5E9B" strokeOpacity="0.24" strokeWidth="2"/>
                        <path d="M18 10.1875C13.6922 10.1875 10.1875 13.6922 10.1875 18C10.1875 22.3078 13.6922 25.8125 18 25.8125C22.3078 25.8125 25.8125 22.3078 25.8125 18C25.8125 13.6922 22.3078 10.1875 18 10.1875ZM18 13.3906C18.2009 13.3906 18.3972 13.4502 18.5643 13.5618C18.7313 13.6734 18.8614 13.832 18.9383 14.0176C19.0152 14.2032 19.0353 14.4074 18.9961 14.6044C18.9569 14.8014 18.8602 14.9824 18.7182 15.1244C18.5761 15.2664 18.3952 15.3632 18.1981 15.4024C18.0011 15.4415 17.7969 15.4214 17.6113 15.3446C17.4258 15.2677 17.2671 15.1375 17.1555 14.9705C17.0439 14.8035 16.9844 14.6071 16.9844 14.4062C16.9844 14.1369 17.0914 13.8786 17.2818 13.6881C17.4723 13.4976 17.7306 13.3906 18 13.3906ZM19.875 22.2188H16.4375C16.2717 22.2188 16.1128 22.1529 15.9956 22.0357C15.8783 21.9185 15.8125 21.7595 15.8125 21.5938C15.8125 21.428 15.8783 21.269 15.9956 21.1518C16.1128 21.0346 16.2717 20.9688 16.4375 20.9688H17.5312V17.5312H16.9062C16.7405 17.5312 16.5815 17.4654 16.4643 17.3482C16.3471 17.231 16.2812 17.072 16.2812 16.9062C16.2812 16.7405 16.3471 16.5815 16.4643 16.4643C16.5815 16.3471 16.7405 16.2812 16.9062 16.2812H18.1562C18.322 16.2812 18.481 16.3471 18.5982 16.4643C18.7154 16.5815 18.7812 16.7405 18.7812 16.9062V20.9688H19.875C20.0408 20.9688 20.1997 21.0346 20.3169 21.1518C20.4342 21.269 20.5 21.428 20.5 21.5938C20.5 21.7595 20.4342 21.9185 20.3169 22.0357C20.1997 22.1529 20.0408 22.2188 19.875 22.2188Z" fill="#1A5E9B"/>
                        <rect width="17.5" height="17.5" transform="translate(8.83337 9.66699)" fill="currentColor" opacity={0.1}/>
                        <path d="M19.0417 11.125H13.2083C12.8216 11.125 12.4506 11.2786 12.1771 11.5521C11.9036 11.8256 11.75 12.1966 11.75 12.5833V24.25C11.75 24.6368 11.9036 25.0077 12.1771 25.2812C12.4506 25.5547 12.8216 25.7083 13.2083 25.7083H21.9583C22.3451 25.7083 22.716 25.5547 22.9895 25.2812C23.263 25.0077 23.4167 24.6368 23.4167 24.25V15.5L19.0417 11.125Z" fill="#1A5E9B" stroke="#1A5E9B" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.0417 11.125V15.5H23.4167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 19.1465H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 22.0625H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.1251 16.2295H15.3959H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        }
        { ['complete'].includes(form.status) &&
            <div className='actionContainer'>
                <Link to={`/reviewIntakeForms/${form._id}`}><button className='viewForm' type='button'>View</button></Link>
                <button className='generateDoc' title='Generate Document' onClick={generateWordDoc}>
                    <svg width="30" viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="33" height="33" rx="7" stroke="#1A5E9B" strokeOpacity="0.24" strokeWidth="2"/>
                        <path d="M18 10.1875C13.6922 10.1875 10.1875 13.6922 10.1875 18C10.1875 22.3078 13.6922 25.8125 18 25.8125C22.3078 25.8125 25.8125 22.3078 25.8125 18C25.8125 13.6922 22.3078 10.1875 18 10.1875ZM18 13.3906C18.2009 13.3906 18.3972 13.4502 18.5643 13.5618C18.7313 13.6734 18.8614 13.832 18.9383 14.0176C19.0152 14.2032 19.0353 14.4074 18.9961 14.6044C18.9569 14.8014 18.8602 14.9824 18.7182 15.1244C18.5761 15.2664 18.3952 15.3632 18.1981 15.4024C18.0011 15.4415 17.7969 15.4214 17.6113 15.3446C17.4258 15.2677 17.2671 15.1375 17.1555 14.9705C17.0439 14.8035 16.9844 14.6071 16.9844 14.4062C16.9844 14.1369 17.0914 13.8786 17.2818 13.6881C17.4723 13.4976 17.7306 13.3906 18 13.3906ZM19.875 22.2188H16.4375C16.2717 22.2188 16.1128 22.1529 15.9956 22.0357C15.8783 21.9185 15.8125 21.7595 15.8125 21.5938C15.8125 21.428 15.8783 21.269 15.9956 21.1518C16.1128 21.0346 16.2717 20.9688 16.4375 20.9688H17.5312V17.5312H16.9062C16.7405 17.5312 16.5815 17.4654 16.4643 17.3482C16.3471 17.231 16.2812 17.072 16.2812 16.9062C16.2812 16.7405 16.3471 16.5815 16.4643 16.4643C16.5815 16.3471 16.7405 16.2812 16.9062 16.2812H18.1562C18.322 16.2812 18.481 16.3471 18.5982 16.4643C18.7154 16.5815 18.7812 16.7405 18.7812 16.9062V20.9688H19.875C20.0408 20.9688 20.1997 21.0346 20.3169 21.1518C20.4342 21.269 20.5 21.428 20.5 21.5938C20.5 21.7595 20.4342 21.9185 20.3169 22.0357C20.1997 22.1529 20.0408 22.2188 19.875 22.2188Z" fill="#1A5E9B"/>
                        <rect width="17.5" height="17.5" transform="translate(8.83337 9.66699)" fill="currentColor" opacity={0.1}/>
                        <path d="M19.0417 11.125H13.2083C12.8216 11.125 12.4506 11.2786 12.1771 11.5521C11.9036 11.8256 11.75 12.1966 11.75 12.5833V24.25C11.75 24.6368 11.9036 25.0077 12.1771 25.2812C12.4506 25.5547 12.8216 25.7083 13.2083 25.7083H21.9583C22.3451 25.7083 22.716 25.5547 22.9895 25.2812C23.263 25.0077 23.4167 24.6368 23.4167 24.25V15.5L19.0417 11.125Z" fill="#1A5E9B" stroke="#1A5E9B" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.0417 11.125V15.5H23.4167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 19.1465H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 22.0625H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.1251 16.2295H15.3959H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        }
        </>
        :
        <>
        { (form.status==='submitted' || form.status === 'complete') &&
            <div className='actionContainer'>
                <button className='generateDoc' title='Generate Document' onClick={generateWordDoc}>
                    <svg width="30" viewBox="0 0 35 35" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <rect x="1" y="1" width="33" height="33" rx="7" stroke="#1A5E9B" strokeOpacity="0.24" strokeWidth="2"/>
                        <path d="M18 10.1875C13.6922 10.1875 10.1875 13.6922 10.1875 18C10.1875 22.3078 13.6922 25.8125 18 25.8125C22.3078 25.8125 25.8125 22.3078 25.8125 18C25.8125 13.6922 22.3078 10.1875 18 10.1875ZM18 13.3906C18.2009 13.3906 18.3972 13.4502 18.5643 13.5618C18.7313 13.6734 18.8614 13.832 18.9383 14.0176C19.0152 14.2032 19.0353 14.4074 18.9961 14.6044C18.9569 14.8014 18.8602 14.9824 18.7182 15.1244C18.5761 15.2664 18.3952 15.3632 18.1981 15.4024C18.0011 15.4415 17.7969 15.4214 17.6113 15.3446C17.4258 15.2677 17.2671 15.1375 17.1555 14.9705C17.0439 14.8035 16.9844 14.6071 16.9844 14.4062C16.9844 14.1369 17.0914 13.8786 17.2818 13.6881C17.4723 13.4976 17.7306 13.3906 18 13.3906ZM19.875 22.2188H16.4375C16.2717 22.2188 16.1128 22.1529 15.9956 22.0357C15.8783 21.9185 15.8125 21.7595 15.8125 21.5938C15.8125 21.428 15.8783 21.269 15.9956 21.1518C16.1128 21.0346 16.2717 20.9688 16.4375 20.9688H17.5312V17.5312H16.9062C16.7405 17.5312 16.5815 17.4654 16.4643 17.3482C16.3471 17.231 16.2812 17.072 16.2812 16.9062C16.2812 16.7405 16.3471 16.5815 16.4643 16.4643C16.5815 16.3471 16.7405 16.2812 16.9062 16.2812H18.1562C18.322 16.2812 18.481 16.3471 18.5982 16.4643C18.7154 16.5815 18.7812 16.7405 18.7812 16.9062V20.9688H19.875C20.0408 20.9688 20.1997 21.0346 20.3169 21.1518C20.4342 21.269 20.5 21.428 20.5 21.5938C20.5 21.7595 20.4342 21.9185 20.3169 22.0357C20.1997 22.1529 20.0408 22.2188 19.875 22.2188Z" fill="#1A5E9B"/>
                        <rect width="17.5" height="17.5" transform="translate(8.83337 9.66699)" fill="currentColor" opacity={0.1}/>
                        <path d="M19.0417 11.125H13.2083C12.8216 11.125 12.4506 11.2786 12.1771 11.5521C11.9036 11.8256 11.75 12.1966 11.75 12.5833V24.25C11.75 24.6368 11.9036 25.0077 12.1771 25.2812C12.4506 25.5547 12.8216 25.7083 13.2083 25.7083H21.9583C22.3451 25.7083 22.716 25.5547 22.9895 25.2812C23.263 25.0077 23.4167 24.6368 23.4167 24.25V15.5L19.0417 11.125Z" fill="#1A5E9B" stroke="#1A5E9B" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M19.0417 11.125V15.5H23.4167" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 19.1465H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M20.5001 22.0625H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.1251 16.2295H15.3959H14.6667" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        }
        </>
        }
        </td>
    </tr>
  )
}
