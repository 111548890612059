import { useEffect, useState } from 'react'
import './IntakeForm6Review.css'

export default function IntakeForm6Review({form, services}) {
    const [inventors, setInventors] = useState([])
    const fallback = 'No information provided'
    const relevantDocumentsQuantity = 9;
    const [relevantDocumentsArray, setRelevantDocumentsArray] = useState([])

    const convertToString = (array) => {
        // convert array to string separated by commas and spaces
        let string = ''
        array.forEach((item, idx) => {
            if (idx === array.length - 1) {
                string += item
            } else {
                string += item + ', '
            }
        })
        return string
    }

    const getListOfInventors = (form) => {
        const inventors = []
        let inventorCount = 1
        while (form[`inventor${inventorCount}`]) {
            inventors.push(form[`inventor${inventorCount}`])
            inventorCount++
        }
        setInventors(inventors)
    }

    const fillListOfRelevantDocuments = () => {
        const relevantDocuments = []
        for (let i = 1; i <= relevantDocumentsQuantity; i++) {
            relevantDocuments.push(i)
        }
        setRelevantDocumentsArray(relevantDocuments)
    }

    useEffect(() => {
        getListOfInventors(form)
        fillListOfRelevantDocuments()
    }, [form])

  return (
    <div className='IntakeForm6Review intakeFormReviewGlobal'>
        <div className='projectNumber'>
            {`${form.projectNumber}`}
        </div>
        <div className='service'>
            {`${services.find(service => service.code === form.service).name}`}
        </div>
        <div className='titleOfInvention'>
            <span>Title of Invention: </span>
            <span>{`${form.titleOfInvention}`}</span>
        </div>
        <div className='submissionDate'>
            <span>Submission Date: </span>
            <span>{`${form.submissionDate? form.submissionDate.split('T')[0] : form.submissionDate}`}</span>
        </div>
        <div className='approvalDate'>
            { form.status === 'complete' &&
            <>
            <span>Approval Date: </span>
            <span>{form.approvalDate.split('T')[0] || 'N/A'}</span>
            </>
            }
        </div>
        <div className='generalInformation'>
            <div className='sectionTitle'>
                1. General Information
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    Legal name of Client
                </div>
                <div className='answer'>
                    {form.companyName}
                </div>
                <div className='questionL1'>
                    Legal address of Client
                </div>
                <div className='answer'>
                    {form.companyAddress}
                </div>
                <div className='questionL1'>
                    Client Representative(s)
                </div>
                <div className='answer'>
                    {convertToString(form.companyRepresentative)}
                </div>
                <div className='questionL1'>
                    Potential Inventor(s) & Address
                </div>
                <div className='answer'>
                    {convertToString(inventors)}
                </div>
            </div>
        </div>
        <div className='technical section'>
            <div className='sectionTitle'>
                2. Technical Section
            </div>
            <div className='sectionContentL1'>
                <div className='questionL1'>
                    2.1 Country of Interest
                </div>
                <div className='answer'>
                    {form.countryOfInterest || fallback}
                </div>
                <div className='questionL1'>
                    2.2 Invention Disclosure
                </div>
                <div className='questionL2'>
                    Has this invention been publicly disclosed, sold, or offered for sale yet? If so, please describe the circumstances under which it was disclosed.
                </div>
                <div className='answer'>
                    {form.inventionDisclosure==='Y' ? 'YES' : 'NO'}
                </div>
                { form.inventionDisclosure==='Y' &&
                <div className='answer'>
                    <div><strong>The circumstances of the disclosure are as follows:</strong></div>
                    <ul>
                        <li>{form.inventionDisclosureDescription}</li>
                    </ul>
                </div>
                }
                <div className='questionL1'>
                    2.3 Objectives of the Invention / Additional Details of Design
                </div>
                <div className='answer'>
                    {form.objectivesOfInvention}
                </div>
                <div className='questionL1'>
                    2.4 Relevant Documents
                </div>
                <div className='answer'>
                    {relevantDocumentsArray.map((element, idx) => (
                        <div key={idx} className='relevantDocuments'>
                            <div className='questionL1'>
                                {`Document ${element}: `}
                            </div>
                            <div className='answer'>
                                { form[`document${element}`] ?
                                <a target='_blank' rel="noreferrer" href={form[`document${element}`]}>{form[`document${element}`]}</a>
                                :
                                <>
                                {fallback}
                                </>
                                }
                            </div>
                            <div className='questionL1'>
                                {`Document ${element} Description: `}
                            </div>
                            <div className='answer'>
                                { form[`document${element}Description`] ?
                                <>
                                {form[`document${element}Description`]}
                                </>
                                :
                                <>
                                {fallback}
                                </>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </div>
  )
}
