import {
    Document, TableRow, TableCell, Table, Paragraph, VerticalAlign, AlignmentType, WidthType, ShadingType,
    Header, TextRun, PageNumber, Footer, HeadingLevel, ExternalHyperlink, Packer, convertInchesToTwip
} from 'docx'

import {saveAs} from 'file-saver'
// import { capitalizeFirstLetter } from './capitalizeFirstLetter'

const relevantDocumentsQuantity = 9;

const convertToString = (array) => {
    // convert array to string separated by commas and spaces
    let string = ''
    array.forEach((item, idx) => {
        if (idx === array.length - 1) {
            string += item
        } else {
            string += item + ', '
        }
    })
    return string
}

const emptyFieldText = 'No information provided.'


const generalInformation = async (form, services) => {
    let elements = []
    let serviceName
    services.forEach(service => {
        if (service.code === form.service) {
            serviceName = service.name
        }
    })

    const service = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
        spacing: { before: 200, after: 500 },
        children: [
            new TextRun({
                text: `${serviceName}`,
                allCaps: true,
                italics: false,
            }),
        ]
    })
    elements.push(service)

    const titleOfInvention = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 200, after: 200 },
        children: [
            new TextRun({
                text: `Title of Invention: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.titleOfInvention}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(titleOfInvention)

    const submissionDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 200, after: 200 },
        children: [
            new TextRun({
                text: `Submission Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.submissionDate? form.submissionDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(submissionDate)

    const approvalDate = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.LEFT,
        spacing: { before: 100, after: 500 },
        children: [
            new TextRun({
                text: `Approval Date: `,
                // allCaps: true,
                italics: false,
            }),
            new TextRun({
                text: `${form.approvalDate? form.approvalDate.split('T')[0] : 'Unavailable'}`,
                // allCaps: true,
                italics: false,
                bold: false,
            }),
        ]
    })
    elements.push(approvalDate)

    const firstHeader1 = new Paragraph({
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "1 General Information",
                allCaps: true,
            })
        ]
    })
    elements.push(firstHeader1)

    // Identification
    const firstHeader2 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "Identification",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstHeader2)

    const identificationTableRows = []

    const identificationTableFirstTableRow = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 1000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal name of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyName}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableFirstTableRow)

    const identificationTableSecondTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Legal address of the company`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${form.companyAddress}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableSecondTableRow)

    const identificationTableThirdTableRow = new TableRow({
        children: [
            new TableCell({
                // width: {
                //     size: 500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "ECECEC",
                        //     fill: "ECECEC",
                        // },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Full name of company’s representative(s)`,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                // width: {
                //     size: 5000,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.2),
                    right: convertInchesToTwip(0.2),
                    top: convertInchesToTwip(0.25),
                    bottom: convertInchesToTwip(0.25),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `${convertToString(form.companyRepresentative)}`,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    identificationTableRows.push(identificationTableThirdTableRow)

    const identificationTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: identificationTableRows
    })
    elements.push(identificationTable)

    // Inventors
    const firstHeader3 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "List of Potential Inventors", 
                // allCaps: true,
            })
        ]
    })
    elements.push(firstHeader3)

    const potentialInventorsTableRows = []

    const potentialInventorsHeaderRow = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 10000,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        shading: {
                            type: ShadingType.SOLID,
                            color: "ECECEC",
                        },
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `ADDRESS (City, State, Country of Residence, and Business Address(es)) `,
                                bold: true,
                            }),
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ],
    })
    potentialInventorsTableRows.push(potentialInventorsHeaderRow)

    let potentialInventorCounter = 1
    while (form[`inventor${potentialInventorCounter}`]) {
        const potentialInventorsTableRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 10000,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0.05),
                        right: convertInchesToTwip(0.05),
                        top: convertInchesToTwip(0.15),
                        bottom: convertInchesToTwip(0.15),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `${form[`inventor${potentialInventorCounter}`]}`,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ],
        })
        potentialInventorsTableRows.push(potentialInventorsTableRow)
        potentialInventorCounter++
    }

    const potentialInventorsTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: potentialInventorsTableRows
    })
    elements.push(potentialInventorsTable)

    return elements
}

const technicalSection = (form) => {
    let elements = []
    const mainHeader = new Paragraph({
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "2 TECHNICAL SECTION",
                allCaps: true,
            })
        ]
    })
    elements.push(mainHeader)

    // 2.1 Country of Interest
    const sec2p1Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 50 },
        children: [
            new TextRun({
                text: "2.1 Country of Interest",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p1Header1)

    const sec2p1Statement = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { before: 50, after: 400 },
        children: [
            new TextRun({
                text: `${form.countryOfInterest}`,
            })
        ]
    })
    elements.push(sec2p1Statement)


    // 2.2 Invention Disclosure
    const inventionDisclosureHeader = new Paragraph({
        spacing: { before: 400, after: 100 },
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "2.2 Invention Disclosure",
                // allCaps: true,
            })
        ]
    })
    elements.push(inventionDisclosureHeader)

    const inventionDisclosureQuestion = new Paragraph({
        spacing: { before: 200, after: 100 },
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "Has this invention been publicly disclosed, sold, or offered for sale, yet? If so, please describe the circumstances under which it was disclosed and the date(s) of first disclosure?",
                // allCaps: true,
            })
        ]
    })
    elements.push(inventionDisclosureQuestion)

    if (form.inventionDisclosure === 'Y') {
        const inventionDisclosureAnswer = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `Answer: `,
                    bold: true,
                    // allCaps: true,
                }),
                new TextRun({
                    text: `YES`,
                    bold: false,
                    // allCaps: true,
                }),
            ]
        })
        elements.push(inventionDisclosureAnswer)

        const inventionDisclosureStatement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 100 },
            children: [
                new TextRun({
                    text: `The circumstances of the disclosure are as follows:`,
                }),
            ]
        })
        elements.push(inventionDisclosureStatement)

        const inventionDisclosureDescription = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 400 },
            text: `${form.inventionDisclosureDescription}`,
            bullet: {
                level: 0
            },
        })
        elements.push(inventionDisclosureDescription)
        
    } else if (form.inventionDisclosure === 'N') {
        const inventionDisclosureStatement = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 200, after: 400 },
            children: [
                new TextRun({
                    text: `Answer: `,
                    bold: true,
                    // allCaps: true,
                }),
                new TextRun({
                    text: `NO`,
                    bold: false,
                    // allCaps: true,
                })
            ]
        })
        elements.push(inventionDisclosureStatement)
    }

    // 2.3 Objectives of Invention
    const ObjectivesOfInventionHeader = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "2.3 Objectives of the Invention / Additional Details of Design",
                // allCaps: true,
            })
        ]
    })
    elements.push(ObjectivesOfInventionHeader)
    if (form.objectivesOfInvention === '') {
        const ObjectivesOfInvention = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 400 },
            children: [
                new TextRun({
                    text: emptyFieldText,
                    italics: true,
                }),
            ]
        })
        elements.push(ObjectivesOfInvention)
    } else {
        const ObjectivesOfInvention = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            spacing: { before: 100, after: 400 },
            children: [
                new TextRun({
                    text: `${form.objectivesOfInvention}`,
                }),
            ]
        })
        elements.push(ObjectivesOfInvention)
    }

    // 2.4 Relevant Documents
    const sec2p2Header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 100 },
        children: [
            new TextRun({
                text: "2.4 Relevant Documents",
                // allCaps: true,
            })
        ]
    })
    elements.push(sec2p2Header1)

    
    let relevantDocumentsCounter = 1
    while (relevantDocumentsCounter <= relevantDocumentsQuantity) {
        if (form[`document${relevantDocumentsCounter}`]) {
            const relevantDocumentHeader = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: `Document ${relevantDocumentsCounter}: `,
                        bold: true,
                        // allCaps: true,
                    }),
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${form[`document${relevantDocumentsCounter}`]}`,
                                style: "Hyperlink",
                                size: 24,
                                font: 'arial',
                            }),
                        ],
                        link: `${form[`document${relevantDocumentsCounter}`]}`,
                    }),
                ]
            })
            elements.push(relevantDocumentHeader)

            const relevantDocumentDescription = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 400 },
                children: [
                    new TextRun({
                        text: `Description: `,
                        bold: true,
                        // allCaps: true,
                    }),
                    new TextRun({
                        text: `${form[`document${relevantDocumentsCounter}Description`]}`,
                    }),
                ]
            })
            elements.push(relevantDocumentDescription)
        } else {
            const relevantDocumentHeader = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 100 },
                children: [
                    new TextRun({
                        text: `Document ${relevantDocumentsCounter}: `,
                        bold: true,
                        // allCaps: true,
                    }),
                    new TextRun({
                        text: emptyFieldText,
                        italics: true,
                    }),
                ]
            })
            elements.push(relevantDocumentHeader)

            const relevantDocumentDescription = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                spacing: { before: 100, after: 400 },
                children: [
                    new TextRun({
                        text: `Description: `,
                        bold: true,
                        // allCaps: true,
                    }),
                    new TextRun({
                        text: emptyFieldText,
                        italics: true,
                    }),
                ]
            })
            elements.push(relevantDocumentDescription)
        }
        relevantDocumentsCounter++
    }
    
    return elements
}


async function saveDocumentToFile(doc, fileName, mimeType) {
    // Create a mime type that will associate the new file with Microsoft Word
    // const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then(blob => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
}

export const generateIntakeFormDocF = async (form, services) => {
    // console.log(data)
    const doc = new Document({
        creator: "Riahi-IP",
        description: `${form.projectNumber} - Intake Form`,
        title: `${form.titleOfInvention}`,
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        bold: true,
                        italics: false,
                        color: "000000",
                        font: "arial",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                        font: "arial",
                        // underline: {
                        //     type: UnderlineType.DOUBLE,
                        //     color: "000000",
                        // },
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                        font: "arial",
                    },
                },
                listParagraph: {
                    run: {
                        color: "000000",
                        font: "arial",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                    name: "My Spectacular Style",
                    basedOn: "Heading1",
                    next: "Heading1",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "990000",
                        font: "arial",
                        size: 24,
                    },
                },
                {
                    id: "MyNormalStyle",
                    name: "My Normal Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyTableStyle",
                    name: "My Table Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 20,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyItalicsStyle",
                    name: "My Italics Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                // 1 GENERAL INFORMATION
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: [PageNumber.CURRENT],
                                        font: 'arial',
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                spacing: {after: 400 },
                                children: [
                                    new TextRun({
                                        text: `${form.projectNumber}`,
                                        allCaps: true,
                                        font: "arial",
                                        size: 24,
                                        bold: false,
                                    }),
                                ]
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                border: {
                                    top: {
                                        color: 'auto',
                                        space: 1,
                                        style: "single",
                                        size: 10,
                                    }
                                },
                                children: [
                                    new TextRun({
                                        text: "Riahi Patents, Inc.",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new TextRun({
                                        text: "                                                                    ",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new ExternalHyperlink({
                                        children: [
                                            new TextRun({
                                                text: "https://riahipatents.com",
                                                style: "Hyperlink",
                                                size: 24,
                                                font: 'arial',
                                            }),
                                        ],
                                        link: "https://riahipatents.com",
                                    }),
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Confidential Document",
                                        size: 24,
                                        font: 'arial',
                                        italics: true,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                           
                        ],
                    }),
                },
                children: await generalInformation(form, services)
            },
            {
                // 2 TECHNICAL INTAKE FORM
                // properties: {
                //     type: SectionType.CONTINUOUS,
                // },
                children: technicalSection(form)
            },
        ],
    });
    const docTitle = (`${form.projectNumber.replaceAll('-','.')} - Intake Form (${form.service}).docx`)
    await saveDocumentToFile(doc, docTitle, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")

    // return Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(`${data.project.projNum}.docx`, buffer);
    // });
}