import './ModifyIntakeForm.css'
import ModifyIntakeForm1 from '../ModifyIntakeForm1/ModifyIntakeForm1';
import ModifyIntakeForm2 from '../ModifyIntakeForm2/ModifyIntakeForm2';
import ModifyIntakeForm3 from '../ModifyIntakeForm3/ModifyIntakeForm3';
import ModifyIntakeForm5 from '../ModifyIntakeForm5/ModifyIntakeForm5';
import ModifyIntakeForm4 from '../ModifyIntakeForm4/ModifyIntakeForm4';
import ModifyIntakeForm6 from '../ModifyIntakeForm6/ModifyIntakeForm6';

export default function ModifyIntakeForm({form, services, updateIntakeForm}) {
    
  return (
    <div className='ModifyIntakeForm modal fade' id='ModifyIntakeFormModal' data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`ModifyIntakeFormModalHeader`} aria-hidden="true">
        
        <div className='modal-dialog modal-dialog-centered'>
        { form &&
            <div className='modal-content'>
            { (form.service === 'IPGAPS' || form.service === 'CPAF' || form.service === 'USPAF' || form.service === 'PCTAF' || form.service === 'USNPAF') &&
            <>
                <ModifyIntakeForm1 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            { (form.service === 'FTOA') &&
            <>
                <ModifyIntakeForm2 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            { (form.service === 'CTAF'|| form.service === 'USTAF' || form.service === 'CTS' || form.service === 'USTS' || form.service === 'TAF' || form.service === 'TS') &&
            <>
                <ModifyIntakeForm3 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            { form.service === 'IPSA' &&
            <>
                <ModifyIntakeForm4 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            { form.service === 'LA' &&
            <>
                <ModifyIntakeForm5 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            { form.service === 'IDDP' &&
            <>
                <ModifyIntakeForm6 form={form} services={services} updateIntakeForm={updateIntakeForm} />
            </>
            }
            </div>
        }
        </div>
    </div>
  )
}
